<template>
  <div class="settings">
    <div>
      <Breadcrumbs :views="[]" :currentView="{ label: 'Ajustes', icon: 'setting' }" />
      <h2>Ajustes</h2>
    </div>

    <div class="content">
      <div class="section">
        <span><h4>Logo de empresa</h4></span>
        <figure class="section__logo">
          <div v-if="isImageLoading" class="loader__container"><loading-spinner /></div>
          <img v-else-if="fileURL" :src="fileURL" alt="" />
          <div v-else>
            <img src="@/assets/imgPlaceholder.svg" alt="" />
          </div>
        </figure>

        <div class="section__buttons section__buttons--logo">
          <input
            @input="(e) => handleInputFile(e.target.files[0])"
            type="file"
            id="actual-btn"
            hidden
          />

          <Button size="small" variant="secondary">
            {{ isImageUploading ? 'Subiendo...' : 'Subir imágen' }}
            <label for="actual-btn">{{ isImageUploading ? 'Subiendo...' : 'Subir imágen' }} </label>
          </Button>
        </div>
      </div>
      <div v-if="users.length" class="section">
        <span>
          <h4>Administrar acceso de usuarios</h4>
        </span>
        <div class="restrictions__table">
          <table>
            <tr>
              <th>Usuario</th>
              <th>Categorias</th>
              <th>Vistas</th>
              <th></th>
            </tr>
            <tr v-for="user in users.filter(({ id }) => id !== userProfile.id)" :key="user.id">
              <td>
                {{ user.name }}
              </td>
              <td>
                <div>
                  <span v-for="restriction in user.restrictions" :key="restriction.categoryId">
                    {{ options.find(({ id }) => id === restriction.optionId).name }}
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <span
                    v-for="view in views.filter((view) =>
                      user.viewsRestrictions ? user.viewsRestrictions[view.requiredRole] : false,
                    )"
                    :key="view.name"
                  >
                    {{ view.text }}
                  </span>
                </div>
              </td>
              <td>
                <unicon
                  @click="onUserSelect(user)"
                  name="pen"
                  fill="currentColor"
                  height="16px"
                  width="16px"
                  class="edit-icon"
                />
              </td>
            </tr>
          </table>
          <empty-state
            v-if="!users.filter(({ id }) => id !== userProfile.id).length"
            label="usuarios"
            :haveAction="false"
          />
        </div>
      </div>
      <div class="section">
        <span>
          <h4>Empresa</h4>
          <unicon
            @click="openModal('empresa')"
            name="pen"
            fill="currentColor"
            height="16px"
            width="16px"
            class="edit-icon"
          />
        </span>
        <div class="section__input">
          <p>Nombre de la empresa</p>
          <p>{{ company.name }}</p>
        </div>
        <div class="section__input">
          <p>RUC</p>
          <p>{{ company.ruc || '---' }}</p>
        </div>
        <div class="section__input">
          <p>Representante legal</p>
          <p>{{ company.legalRepresentative || '---' }}</p>
        </div>
        <div class="section__input">
          <p>Dirección</p>
          <p>{{ company.address || '---' }}</p>
        </div>
      </div>
      <div class="section">
        <span>
          <h4>Información de usuario</h4>
          <unicon
            @click="openModal('información')"
            size="small"
            variant="primary"
            name="pen"
            fill="currentColor"
            height="16px"
            width="16px"
            class="edit-icon"
          />
        </span>
        <div class="section__input">
          <p>Nombre</p>
          <p>{{ userProfile.name }}</p>
        </div>
        <div class="section__input">
          <p>Email</p>
          <p>{{ userProfile.email }}</p>
        </div>
      </div>

      <div class="section">
        <h4>Contraseña</h4>
        <div class="section__buttons">
          <Button @click="openModal('contraseña')" size="small" variant="secondary">
            Cambiar contraseña
          </Button>
        </div>
      </div>
    </div>

    <Modal size="sm" ref="modal">
      <template #title>Editar {{ modal }}</template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(onAction)">
            <div class="category__modal">
              <div v-if="modal === 'empresa'">
                <custom-input
                  v-model="formCompany.name"
                  type="text"
                  label="Nombre"
                  rules="required"
                />
                <custom-input v-model="formCompany.ruc" type="text" label="RUC" rules="required" />
                <custom-input
                  v-model="formCompany.legalRepresentative"
                  type="text"
                  label="Representante legal"
                  rules="required"
                />
                <custom-input
                  v-model="formCompany.address"
                  type="text"
                  label="Dirección"
                  rules="required"
                />
              </div>
              <div v-else-if="modal === 'información'">
                <custom-input
                  v-model="formAccount.name"
                  type="text"
                  label="Nombre"
                  rules="required"
                />
                <custom-input
                  v-model="formAccount.email"
                  type="text"
                  label="Email"
                  rules="required"
                />
              </div>
              <div v-else-if="modal === 'accesos'">
                <span>
                  <h5>{{ selectedUser.name }}</h5>
                </span>
                <Tabs
                  :tabList="[
                    { title: 'Categorias', icon: 'folder-open' },
                    { title: 'Vistas', icon: 'eye' },
                  ]"
                >
                  <template v-slot:tabPanel-1>
                    <div class="users__restrictions">
                      <span
                        v-for="restriction in selectedUser.restrictions"
                        :key="restriction.categoryId"
                      >
                        <custom-select
                          v-model="restriction.categoryId"
                          :options="categories"
                          label=""
                        />
                        <custom-select
                          v-model="restriction.optionId"
                          :options="
                            options.filter(
                              ({ categoryId }) => categoryId === restriction.categoryId,
                            )
                          "
                          label=""
                        />
                      </span>
                      <Button
                        @click="addRestriction(selectedUser)"
                        size="small"
                        variant="secondary-text"
                      >
                        <unicon width="16px" height="16px" name="plus" fill="#B1B0AE"></unicon>
                        Agregar restricción
                      </Button>
                    </div>
                  </template>
                  <template v-slot:tabPanel-2>
                    <div class="views__restrictions">
                      <table>
                        <tr>
                          <th>Vista</th>
                          <th>Permiso</th>
                        </tr>
                        <tr v-for="view in views" :key="view.name">
                          <td>{{ view.text }}</td>
                          <td>
                            <span>
                              <switch-component
                                v-model="selectedUser.viewsRestrictions[view.requiredRole]"
                              />
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </template>
                </Tabs>
              </div>
              <div v-else>
                <custom-input
                  v-model="newPassword"
                  type="password"
                  label="Contraseña"
                  rules="required|min:6|confirmed:passwordConfirmation"
                  placeholder="Ingresar nueva contraseña..."
                />
                <custom-input
                  v-model="confirmationPassword"
                  type="password"
                  label="Repetir contraseña"
                  rules="required|min:6"
                  vid="passwordConfirmation"
                  placeholder="Repetir nueva contraseña..."
                />
              </div>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="closeModal"
              >
                Cancelar
              </Button>
              <Button type="submit" :disabled="invalid || isLoading" variant="primary" size="small">
                {{ !isLoading ? 'Guardar cambios' : 'Guardando...' }}
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Button from '@/components/buttons/Button.vue';
import Modal from '@/components/Modal.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import CustomSelect from '@/components/custom/CustomSelect.vue';
import SwitchComponent from '@/components/Switch.vue';
import EmptyState from '@/components/EmptyState.vue';
import Tabs from '@/components/Tabs.vue';
import TABS from '@/viewsData';

export default {
  components: {
    Breadcrumbs,
    Button,
    Modal,
    ValidationObserver,
    CustomInput,
    LoadingSpinner,
    CustomSelect,
    Tabs,
    SwitchComponent,
    EmptyState,
  },
  name: 'Settings',
  data() {
    return {
      isLoading: false,
      isImageUploading: false,
      isImageLoading: false,
      modal: '',
      newPassword: '',
      confirmationPassword: '',
      formCompany: {},
      formAccount: {},
      fileURL: '',
      selectedUser: {},
      views: TABS.filter(
        (tab) => !['Inicio', 'Admin', 'Estructura Salarial Externa'].includes(tab.text),
      ),
    };
  },

  async mounted() {
    try {
      this.isLoading = true;
      if (!this.categories.length) await this.fetchCategories();
      if (!this.options.length) await this.fetchOptions();
      await this.setLogoURL();
      await this.fetchUsersFromCompany(this.company.id);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
    this.formCompany = await JSON.parse(JSON.stringify(this.company));
    this.formAccount = await JSON.parse(JSON.stringify(this.userProfile));
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions([
      'updateUser',
      'updateUserProfile',
      'updateCompany',
      'updatePassword',
      'uploadLogo',
      'getLogo',
    ]),
    ...mapActions('admin', ['fetchUsersFromCompany', 'assignUserCredentials']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('options', ['fetchOptions']),
    onUserSelect(user) {
      this.selectedUser = user;
      if (!this.selectedUser.viewsRestrictions) {
        this.selectedUser.viewsRestrictions = {};
        this.views.forEach((view) => {
          this.selectedUser.viewsRestrictions[view.requiredRole] = false;
        });
      }
      this.openModal('accesos');
    },
    openModal(modalName) {
      this.$refs.modal.open();
      this.modal = modalName;
    },
    closeModal() {
      this.$refs.modal.close();
    },

    addRestriction(user) {
      if (!user.restrictions) {
        this.$set(user, 'restrictions', []);
      }
      user.restrictions.push({ categoryId: '', optionId: '' });
    },

    async handleInputFile(file) {
      if (!file) return;
      try {
        this.isImageUploading = true;
        await this.uploadLogo(file);
        this.setAlert({
          state: 'success',
          message: 'Logo subido',
        });
        this.setLogoURL();
        this.isImageUploading = false;
      } catch (e) {
        console.log(e);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      }
    },

    async setLogoURL() {
      try {
        this.isImageLoading = true;
        const file = await this.getLogo();
        const url = await file.getDownloadURL();
        this.fileURL = url;
        this.isImageLoading = false;
      } catch (e) {
        console.log(e);
        this.isImageLoading = false;
      }
    },

    async onAction() {
      this.isLoading = true;
      if (this.modal === 'empresa') {
        try {
          await this.updateCompany(this.formCompany);
          this.setAlert({
            state: 'success',
            message: 'Empresa actualizada',
          });
        } catch (e) {
          console.log(e);
          this.setAlert({
            state: 'error',
            message: 'Ocurrió un error, por favor inténtelo nuevamente',
          });
        }
      } else if (this.modal === 'información') {
        try {
          await this.updateUserProfile(this.formAccount);
          this.setAlert({
            state: 'success',
            message: 'Información actualizada',
          });
        } catch (e) {
          this.setAlert({
            state: 'error',
            message: 'Ocurrió un error, por favor inténtelo nuevamente',
          });
        }
      } else if (this.modal === 'accesos') {
        try {
          this.updateUser(this.selectedUser);
          this.assignUserCredentials({
            uid: this.selectedUser.id,
            roles: this.selectedUser.viewsRestrictions,
          });
          this.setAlert({
            state: 'success',
            message: 'Accesos actualizados',
          });
        } catch (e) {
          this.setAlert({
            state: 'error',
            message: 'Ocurrió un error, por favor inténtelo nuevamente',
          });
        }
      } else {
        try {
          await this.updatePassword(this.newPassword);
          this.setAlert({
            state: 'success',
            message: 'Contraseña actualizada',
          });
        } catch (e) {
          this.setAlert({
            state: 'error',
            message: 'Ocurrió un error, por favor inténtelo nuevamente',
          });
        }
      }
      this.isLoading = false;
      this.closeModal();
    },
  },

  computed: {
    ...mapState({
      company: (state) => ({
        ...state.company,
        colors: state.company.colors,
      }),
      userProfile: (state) => state.userProfile,
      users: (state) => state.admin.users,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
  },
};
</script>

<style lang="scss" scoped>
.settings {
  border-radius: 10px;
  background-color: white;
}

.content {
  width: 100%;
  overflow: auto;
  background-color: white;
  margin-top: 1.5rem;
}

.section {
  padding: 1rem 0 1rem 0;
  margin-right: 1rem;
  border-top: solid 2px var(--gray-color-200);
  span {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    margin-bottom: 0.25rem;
  }
  .section__input {
    margin-bottom: 0.4rem;
    flex-grow: 1;
    p {
      &:first-child {
        font-size: 0.75rem;
      }
      &:nth-child(2) {
        font-size: 0.875rem;
        color: var(--font-color-700);
        background-color: var(--gray-color-100);
        padding: 4px 10px;
        box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px inset;
        border-radius: 3px;
      }
    }
  }

  .section__buttons {
    margin: 1rem 0;
    display: flex;
    gap: 0.5rem;
    width: fit-content;
    position: relative;
    &--logo {
      cursor: pointer;
      button {
        min-width: 7rem;
      }
      label {
        cursor: pointer;
        height: 100%;
        position: absolute;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
    }
  }

  .section__logo {
    img {
      background-color: var(--gray-color-100);
      border-radius: 5px;
      width: 250px;
      height: 150px;
      padding: 1rem;
      object-fit: contain;
    }
    .loader__container {
      background-color: var(--gray-color-100);
      border-radius: 5px;
      width: 250px;
      height: 150px;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.form__validation,
form,
.category__modal {
  height: 100%;
}

.category__modal {
  padding: 1rem 2rem;
  div {
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }
}

.edit-icon {
  cursor: pointer;
  border-radius: 3.5px;
  padding: 0.2rem;
  // color: var(--main-color-500);
  &:hover {
    background-color: var(--gray-color-100);
  }
}
.users__restrictions {
  height: 18rem;
  min-height: 18rem;
  overflow: auto;
  & > span {
    display: flex;
    gap: 1rem;
  }
  .button {
    justify-content: left;
  }
}
.views__restrictions {
  height: 18rem;
  min-height: 18rem;
  overflow: auto;
}
.tabs > div {
  overflow: hidden;
}

.restrictions__table {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  margin-top: 1rem;
  div {
    display: flex;
    gap: 0.5rem;
  }
  span {
    padding: 0.375rem;
    font-size: 0.75rem;
    font-weight: var(--medium);
    color: var(--font-color-700);
    background-color: var(--gray-color-100);
    border-radius: 4px;
    text-align: center;
    margin-bottom: 0;
  }
  td:last-child {
    width: 0;
    padding-right: 0.8rem;
  }
}
</style>
